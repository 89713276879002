import { localhostUrl, productionUrl } from "./hosts";
import { Store } from "./store";
export class BrowserStore extends Store {
    constructor() {
        super(...arguments);
        this.sessionStorage = sessionStorage;
        this.localStorage = localStorage;
    }
    fetchGlobalOverrides() {
        const searchParams = new URLSearchParams(window.location.search);
        const overrides = {};
        for (const [key, value] of searchParams) {
            if (key.endsWith("-version")) {
                overrides[key.replace("-version", "")] = value;
            }
        }
        return overrides;
    }
    createRandomThreshold() {
        return {
            /**
             * This is a random number between 1 and 100.
             * Must be at least 1 so that a rollout of 0 excludes everyone.
             * Must be at most 100 so that a rollout of 100 includes everyone.
             */
            value: Math.ceil(Math.random() * 100),
            /**
             * This value expires in one week.
             */
            expires: Date.now() + 1000 * 60 * 60 * 24 * 7,
        };
    }
    thresholdIsExpired(threshold) {
        return threshold.expires < Date.now();
    }
    async fetchManifest(name, param) {
        let url = `${productionUrl}/${name}/runtime/manifest.json`;
        if (param) {
            url += `?${param}`;
        }
        return fetch(url).then((res) => res.json());
    }
    fetchRemoteEntry(name, url) {
        const globalNamespace = `__LR__${name}`;
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = url;
            script.async = true;
            script.onload = () => {
                const mod = window[globalNamespace];
                resolve(mod);
            };
            script.onerror = () => {
                document.head.removeChild(script);
                let message = `Failed to load module "${name}" from "${url}"`;
                if (url.startsWith(localhostUrl)) {
                    message += `\n\nDid you forget to start your development server?`;
                }
                reject(new Error(message));
            };
            document.head.appendChild(script);
        });
    }
    async captureException(error, extra = {}) {
        /**
         * Fetch Sentry asynchronously because it is a shared module under federation.
         * If we attempt a static import, then the entire runtime has to be loaded asynchronously
         * which will result in the __LR__ namespace not being immediately available.
         */
        await import("./sentry.bootstrap").then((Sentry) => {
            Sentry.captureException(error, {
                level: "fatal",
                extra: extra,
                contexts: {
                    microfrontend: {
                        // lobster-roll-runtime
                        dsn: "https://cb89305d5f41879d4bd42578af7aa2fe@o1109393.ingest.us.sentry.io/4508082240684032",
                        release: process.env.VERSION,
                    },
                },
            });
        });
    }
    displayOverrides(overrides) {
        if (Object.keys(overrides).length === 0) {
            return;
        }
        const stylesheet = document.createElement("style");
        stylesheet.textContent = `
      [data-lobster-roll-overrides] {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10000;
      }
    `;
        stylesheet.dataset.lobsterRollOverridesStyle = "";
        document.head.appendChild(stylesheet);
        const element = document.createElement("div");
        element.dataset.lobsterRollOverrides = "";
        window.addEventListener("load", () => {
            document.body.appendChild(element);
            /**
             * Wait for the next tick to add the "tucked" class so that the transition works.
             */
            setTimeout(() => shadow.querySelector("#container")?.classList.add("tucked"), 0);
        });
        const shadow = element.attachShadow({ mode: "closed" });
        shadow.innerHTML = `
      <style>
        * {
          font-family: sans-serif;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          box-sizing: border-box;
        }

        #container {
          position: relative;
          border-top: 1px solid black;
          color: white;
          bottom: 0px;
          transition: bottom 0.2s ease 1s;
        }

        #container.tucked {
          bottom: -30px;
        }

        #container:hover {
          bottom: 0px !important;
          transition-delay: 0s;
        }

        #container:has(details#versions[open]) {
          bottom: 0px !important;
        }

        #container:has(details#hide-button[open]) {
          display: none;
        }

        #container.threshold { background-color: mediumseagreen; }
        #container.dev { background-color: royalblue; }
        #container.version { background-color: darkorange; }
        #container.preview { background-color: orangered; }

        details#versions {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        details#versions summary {
          cursor: pointer;
          text-decoration: underline;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        }

        details#versions[open] {
          padding: 5px;
          padding-top: 0;
        }

        details#versions[open] summary {
          padding-top: 5px;
        }

        details#versions:not([open]) summary {
          padding: 5px;
        }

        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: 5px 0;
          padding: 5px;
          border: 1px black dashed;
        }

        label:has(input[type="checkbox"]:not(:checked)) {
          text-decoration: line-through;
        }

        input[type="checkbox"] {
          accent-color: black;
          margin-right: 10px;
        }

        button#form-button {
          display: block;
          padding: 5px;
          background-color: white;
          border: 1px solid black;
          cursor: pointer;
          color: black;
          width: 100%;
          font-size: 16px;
        }

        button#form-button:hover {
          background-color: #eee;
        }

        button#form-button:active {
          background-color: #ddd;
        }

        #hide {
          position: absolute;
          right: 5px;
          top: 5px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          background: none;
        }

        details#hide-button {
          background: none;
          border: none;
          color: white;
          cursor: pointer;
        }
          
        details#hide-button summary {
          list-style-type: none;
          padding: 0 5px;
        }

        details#hide-button:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        details#hide-button:active {
          background: rgba(255, 255, 255, 0.3);
        }
      </style>

      <div id="container" class="${Object.values(overrides)
            .map((o) => o.type)
            .join(" ")}">
        <form method="GET" action="">
          <details id="versions">
            <summary>This page was loaded with overrides: ${Object.keys(overrides).join(", ")}</summary>
            ${Object.entries(overrides)
            .map(([name, override]) => {
            const clearUrl = new URL(window.location.href);
            clearUrl.searchParams.set(`${name}-version`, "clear");
            /**
             * Include a hidden input to clear the override if the checkbox is unchecked.
             */
            return `
                  <label for="${name}-version">
                    <input type="hidden" name="${name}-version" value="clear">
                    <input type="checkbox" id="${name}-version" name="${name}-version" value="${override.value}" checked>
                    ${name}@${override.value}
                  </label>`;
        })
            .join("")}
            <button id="form-button" type="submit">Update Overrides</button>
          </details>
        </form>
        <div id="hide">
          <details id="hide-button" role="button"><summary>&#x2715;</summary></details>
        </div>
      </div>
    `;
    }
}
