const LOG_PREFIX = "[Lobster Roll]";
export function warn(message) {
    console.warn(LOG_PREFIX, message);
}
export function reject(message) {
    return Promise.reject(error(message));
}
export function error(message) {
    return new Error(`${LOG_PREFIX} ${message}`);
}
