import { isObject } from "./is-object";
import { warn } from "./log";
function isRollout(value) {
    return isObject(value) && typeof value.percentage === "number";
}
function isVersion(value) {
    return isObject(value) && typeof value.id === "string" && typeof value.remoteEntryUrl === "string";
}
export function isPartialManifest(value) {
    return isObject(value) && isVersion(value.current);
}
export function normalizeManifest(manifest) {
    if (manifest.next === undefined) {
        manifest.next = manifest.current;
    }
    else if (!isVersion(manifest.next)) {
        warn(`Ignoring invalid data: next version must be a valid version but received "${JSON.stringify(manifest.next)}"`);
        manifest.next = manifest.current;
    }
    if (isRollout(manifest.rollout)) {
        if (manifest.rollout.percentage < 0) {
            manifest.rollout.percentage = 0;
        }
        else if (manifest.rollout.percentage > 100) {
            manifest.rollout.percentage = 100;
        }
    }
    else {
        manifest.rollout = { percentage: 0 };
    }
    return manifest;
}
