var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Store_instances, _Store_cache, _Store_fetchSessionOverrides, _Store_setSessionOverrides, _Store_calculateVersionThreshold, _Store_resolveCacheEntry, _Store_fetchManifest, _Store_fetchRemoteEntry;
import { localhostUrl, productionUrl } from "./hosts";
import { isObject } from "./is-object";
import { reject, warn } from "./log";
import { isPartialManifest, normalizeManifest } from "./manifest";
import { isRemoteEntry } from "./remote-entry";
import { isThreshold } from "./threshold";
const COHORTS_KEY = "__LR__thresholds";
const OVERRIDES_KEY = "__LR__overrides";
const DEV_MATCHER = /^dev:(?<port>\d+)$/;
const VERSION_MATCHER = /^\d+\.\d+\.\d+/;
const PREVIEW_MATCHER = /0\.0\.0-pre-/;
function sanitize(str) {
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/'/g, "&#39;")
        .replace(/"/g, "&quot;");
}
function sanitizeObject(overrides) {
    const result = {};
    for (const name in overrides) {
        const version = overrides[name];
        if (typeof version !== "string") {
            continue;
        }
        result[sanitize(name)] = sanitize(version);
    }
    return result;
}
async function invertCacheEntry(entry) {
    return {
        manifest: await entry.manifest,
        threshold: await entry.threshold,
        version: await entry.version,
        remoteEntry: await entry.remoteEntry,
    };
}
export class Store {
    constructor() {
        _Store_instances.add(this);
        _Store_cache.set(this, new Map());
    }
    register(name, partial) {
        if (typeof name !== "string") {
            warn(`Failed to register library: name must be a string but received "${name}"`);
            return false;
        }
        if (!isPartialManifest(partial)) {
            warn(`Failed to register library: manifest must be a valid manifest but received "${JSON.stringify(partial)}`);
            return false;
        }
        const manifest = normalizeManifest(partial);
        __classPrivateFieldGet(this, _Store_instances, "m", _Store_resolveCacheEntry).call(this, name, { manifest: Promise.resolve(manifest) });
        return true;
    }
    load(name) {
        if (typeof name !== "string") {
            return reject(`Failed to load library: name must be a string but received "${name}"`);
        }
        if (sanitize(name) !== name) {
            return reject(`Failed to load library: name must not contain any HTML but received "${name}"`);
        }
        const entry = __classPrivateFieldGet(this, _Store_instances, "m", _Store_resolveCacheEntry).call(this, name);
        return entry.remoteEntry;
    }
    async getCache() {
        const sync = {};
        for (const [name, entry] of __classPrivateFieldGet(this, _Store_cache, "f")) {
            sync[name] = await invertCacheEntry(entry);
        }
        return sync;
    }
    async debug() {
        const cache = await this.getCache();
        const data = {};
        for (const name in cache) {
            const entry = cache[name];
            data[name] = {
                activeVersion: entry.version.id,
                currentVersion: entry.manifest.current.id,
                nextVersion: entry.manifest.next.id,
                rollout: entry.manifest.rollout.percentage,
                threshold: entry.threshold,
            };
        }
        console.group("Lobster Roll Debug");
        console.table(data);
        console.groupCollapsed("Cache");
        console.log(cache);
        console.groupEnd();
        console.groupCollapsed("Environment");
        console.table(process.env);
        console.groupEnd();
        console.groupEnd();
    }
    applyOverrides() {
        const sessionOverrides = __classPrivateFieldGet(this, _Store_instances, "m", _Store_fetchSessionOverrides).call(this);
        const globalOverrides = this.fetchGlobalOverrides();
        const overrides = sanitizeObject({ ...sessionOverrides, ...globalOverrides });
        __classPrivateFieldGet(this, _Store_instances, "m", _Store_setSessionOverrides).call(this, overrides);
        let overridesToDisplay = {};
        for (const name in overrides) {
            const override = overrides[name];
            if (override === "clear") {
                delete overrides[name];
                __classPrivateFieldGet(this, _Store_instances, "m", _Store_setSessionOverrides).call(this, overrides);
                continue;
            }
            else if (override === "current") {
                overridesToDisplay[name] = { type: "threshold", value: override };
                __classPrivateFieldGet(this, _Store_instances, "m", _Store_resolveCacheEntry).call(this, name, { threshold: Promise.resolve(101) });
                continue;
            }
            else if (override === "next") {
                overridesToDisplay[name] = { type: "threshold", value: override };
                __classPrivateFieldGet(this, _Store_instances, "m", _Store_resolveCacheEntry).call(this, name, { threshold: Promise.resolve(0) });
                continue;
            }
            const matchDev = override.match(DEV_MATCHER);
            if (matchDev) {
                const { port } = matchDev.groups;
                if (!port) {
                    continue;
                }
                const version = {
                    id: `dev:${port}`,
                    remoteEntryUrl: `${localhostUrl}:${port}/versions/dev/remoteEntry.js`,
                };
                overridesToDisplay[name] = { type: "dev", value: `dev:${port}` };
                __classPrivateFieldGet(this, _Store_instances, "m", _Store_resolveCacheEntry).call(this, name, { version: Promise.resolve(version) });
                continue;
            }
            else if (VERSION_MATCHER.test(override)) {
                const version = {
                    id: override,
                    remoteEntryUrl: `${productionUrl}/${name}/versions/${override}/remoteEntry.js`,
                };
                const type = PREVIEW_MATCHER.test(override) ? "preview" : "version";
                overridesToDisplay[name] = { type, value: override };
                __classPrivateFieldGet(this, _Store_instances, "m", _Store_resolveCacheEntry).call(this, name, { version: Promise.resolve(version) });
                continue;
            }
        }
        this.displayOverrides(overridesToDisplay);
    }
}
_Store_cache = new WeakMap(), _Store_instances = new WeakSet(), _Store_fetchSessionOverrides = function _Store_fetchSessionOverrides() {
    try {
        const str = this.sessionStorage.getItem(OVERRIDES_KEY) ?? "{}";
        const data = JSON.parse(str);
        /**
         * If the data is not an object, then its probably been tampered with.
         * In this case, we'll just remove it and return "none".
         */
        if (!isObject(data)) {
            this.sessionStorage.setItem(OVERRIDES_KEY, "{}");
            return {};
        }
        for (const name0 in data) {
            const versionId = data[name0];
            if (typeof versionId !== "string") {
                delete data[name0];
                continue;
            }
        }
        return data;
    }
    catch {
        try {
            this.sessionStorage.setItem(OVERRIDES_KEY, "{}");
        }
        catch { }
        return {};
    }
}, _Store_setSessionOverrides = function _Store_setSessionOverrides(overrides) {
    try {
        this.sessionStorage.setItem(OVERRIDES_KEY, JSON.stringify(overrides));
    }
    catch { }
}, _Store_calculateVersionThreshold = function _Store_calculateVersionThreshold(name, versionId, storage = this.localStorage) {
    var _a;
    try {
        const str = storage.getItem(COHORTS_KEY) ?? "{}";
        const data = JSON.parse(str);
        if (!isObject(data)) {
            storage.setItem(COHORTS_KEY, "{}");
            return __classPrivateFieldGet(this, _Store_instances, "m", _Store_calculateVersionThreshold).call(this, name, versionId, storage);
        }
        /**
         * Clean up invalid and expired thresholds.
         */
        for (const name0 in data) {
            const versions = data[name0];
            if (!isObject(versions)) {
                delete data[name0];
                continue;
            }
            for (const version0 in versions) {
                const threshold = versions[version0];
                if (!isThreshold(threshold) || this.thresholdIsExpired(threshold)) {
                    delete data[name0][version0];
                    continue;
                }
            }
        }
        data[name] ?? (data[name] = {});
        (_a = data[name])[versionId] ?? (_a[versionId] = this.createRandomThreshold());
        storage.setItem(COHORTS_KEY, JSON.stringify(data));
        return data[name][versionId].value;
    }
    catch {
        try {
            storage.setItem(COHORTS_KEY, "{}");
        }
        catch {
            /** In case this fails, we want to make sure that we keep going. */
        }
        /**
         * If this is true, then storage is already sessionStorage and we shouldn't attempt to recurse again.
         * To avoid an infinite loop, we'll just return 101. It's 101 instead of 100 because we want to make
         * sure that we never load the next version even if the rollout is 100%.
         */
        if (storage === this.sessionStorage) {
            return 101;
        }
        /**
         * If we somehow failed, try again with sessionStorage.
         */
        return __classPrivateFieldGet(this, _Store_instances, "m", _Store_calculateVersionThreshold).call(this, name, versionId, this.sessionStorage);
    }
}, _Store_resolveCacheEntry = function _Store_resolveCacheEntry(name, partial = {}) {
    let cached = __classPrivateFieldGet(this, _Store_cache, "f").get(name);
    if (cached) {
        if (Object.keys(partial).length > 0) {
            warn(`Failed to resolve cache entry for "${name}": entry already exists`);
        }
        return cached;
    }
    const manifest = partial.manifest ?? __classPrivateFieldGet(this, _Store_instances, "m", _Store_fetchManifest).call(this, name);
    const threshold = partial.threshold ?? manifest.then((manifest) => __classPrivateFieldGet(this, _Store_instances, "m", _Store_calculateVersionThreshold).call(this, name, manifest.current.id));
    const version = partial.version ??
        manifest.then(async (manifest) => {
            const threshold0 = await threshold;
            return threshold0 > manifest.rollout.percentage ? manifest.current : manifest.next;
        });
    const remoteEntry = partial.remoteEntry ?? version.then((v) => __classPrivateFieldGet(this, _Store_instances, "m", _Store_fetchRemoteEntry).call(this, name, v.remoteEntryUrl));
    const entry = {
        manifest,
        threshold,
        version,
        remoteEntry,
    };
    __classPrivateFieldGet(this, _Store_cache, "f").set(name, entry);
    return entry;
}, _Store_fetchManifest = function _Store_fetchManifest(name) {
    /**
     * If we fail to fetch the manifest, we'll try again with a timestamp parameter.
     * We divide the time by 2 minutes and floor it to make sure that _everyone_ uses
     * the same param within a 2 minute window. Turn the number into a base-36 string
     * to obfuscate that it's a timestamp.
     */
    const param = Math.floor(Date.now() / (1000 * 60 * 2)).toString(36);
    return this.fetchManifest(name)
        .catch(async () => {
        /**
         * Capture the exception, so that we can debug it later.
         */
        await this.captureException(new Error(`Failed to fetch manifest.json`), { name });
        return this.fetchManifest(name, param);
    })
        .catch(async () => {
        /**
         * If fetching the manifest with a timestamp fails, we'll fallback to a hardcoded URL.
         */
        await this.captureException(new Error(`Failed to fetch manifest.json`), { name, param });
        return {
            current: {
                id: "fallback",
                remoteEntryUrl: `${productionUrl}/${name}/fallback/remoteEntry.js?${param}`,
            },
        };
    })
        .then(normalizeManifest);
}, _Store_fetchRemoteEntry = function _Store_fetchRemoteEntry(name, remoteEntryUrl) {
    const url = new URL(remoteEntryUrl);
    const retry = (count) => async () => {
        await this.captureException(new Error(`Failed to fetch remoteEntry.js`), {
            name,
            remoteEntryUrl: url.toString(),
        });
        url.searchParams.set("retry", String(count));
        return this.fetchRemoteEntry(name, url.toString());
    };
    return this.fetchRemoteEntry(name, url.toString())
        .catch(retry(1))
        .catch(retry(2))
        .catch(retry(3))
        .then((res) => {
        if (!isRemoteEntry(res)) {
            return reject(`Failed to load remote entry for "${name}": remote entry must be a valid WebpackRemoteEntry but received "${JSON.stringify(res)}"`);
        }
        return res;
    });
};
